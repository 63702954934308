import Layout, { Content, Header } from "antd/lib/layout/layout";
import { Outlet } from "react-router-dom";
import { AppDrawer, AppHeader, AppLogo, LeftMenu, RightMenu } from "../header";
import { AppFooter } from "../footer";

import "./Main.css";


export function Main() {
  return (
    <Layout className="layout">
      <AppHeader>
        <AppLogo />
        <LeftMenu className="main-menu navigation" />
        <RightMenu className="main-menu auth" />
        <AppDrawer />
      </AppHeader>
      <Content className="site-content">
        <Outlet />
      </Content>
      <AppFooter />
    </Layout>
  );
}
