import "./NewsLetterForm.css";
import { Input, Button } from "antd";

export default function NewsLetterForm() {
  return (
    <div className="NewsLetterContainer">
      <p>Get latest property updates</p>
      <p>
        Receive alerts when we have new properties available for Property For
        Rent
      </p>
      <Input placeholder="Enter your name" className="input" />
      <Input placeholder="Enter your email address" className="input" />
      <Button type="primary" className="button" block>
        {" "}
        Subscribe Now{" "}
      </Button>
    </div>
  );
}
