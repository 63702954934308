import { Affix, Col, Row } from 'antd'
import { SearchBox } from '../../core/components'

import './Home.css'
import Property from '../property/Property'


export default function Home() {
  
  return (
    <div className='home-container'>
      <Affix offsetTop={0}>
        <Row className='search-container'>
          <SearchBox />
        </Row>
      </Affix>
      <Row justify='center' className='home page-content' gutter={[0, 40]}>
        <Property />
      </Row>
    </div>
  )
}
