import { Button, Drawer } from 'antd'
import { useState } from 'react'
import { CloseOutlined, MenuOutlined } from '@ant-design/icons'
import LeftMenu from './LeftMenu'
import RightMenu from './RightMenu'

export default function AppDrawer() {
  const [visible, setVisible] = useState(false)

  const handleClose = () => {
    setVisible(false)
  }

  return (
    <>
      <Button icon={<MenuOutlined />} onClick={() => setVisible(true)} className='mobile-menu-button' />
      <Drawer
        title={<Button icon={<CloseOutlined />} onClick={() => setVisible(false)} className='mobile-menu-button' />}
        placement='right'
        closable={false}
        onClose={() => setVisible(false)}
        open={visible}
      >
        <LeftMenu mode='vertical' className='mobile-menu' onClose={handleClose} />
        <RightMenu mode='vertical' className='mobile-menu' />
      </Drawer>
    </>
  )
}
