import { ReactComponent as Logo } from 'src/app/asset/images/rentfree-logo.svg'
import EntryWrapper from 'src/app/core/components/entry-wrapper/EntryWrapper'
import LoginForm from './components/LoginForm'
import ThirdParties from 'src/app/core/components/entry-wrapper/ThirdParties'
import './Login.css'

export default function Login() {
  return (
    <EntryWrapper>
      <div className='login-container'>
        <a href='/'>
          <Logo />
        </a>

        <div className='login'>
          <p className='login-text'>Login</p>
          <p className='login__welcome-back'>Welcome back! Please enter your details.</p>
          <LoginForm />
          <ThirdParties page='Login' />
          <div className='signup'>
            Don’t have an account? <a href='/register'>Sign up</a>
          </div>
        </div>
      </div>
    </EntryWrapper>
  )
}
