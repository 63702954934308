import { Col } from "antd";
import "./ServiceBox.css";

interface ServiceBoxProps {
    icon: React.ReactElement;
    header: string;
    content: string;
    link: string;
}
export function ServiceBox({ icon, header, content, link}: ServiceBoxProps) {
    return (
        <Col sm={24} lg={8}>
            <div className="service-box">
                <div className="service-box__icon">
                    {icon}
                </div>
                <div>
                    <p className="service-box__header">
                        {header}
                    </p>
                    <p className="service-box__content">
                        {content}
                    </p>
                </div>
                <div>
                    <a href={link} className="service-box__link">
                        Read more
                    </a>
                </div>
            </div>
        </Col>
    )
}