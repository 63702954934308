import { Row } from "antd";
import { ServiceBox } from "src/app/core/components";
import { AppstoreAddOutlined, HomeOutlined, SearchOutlined } from "@ant-design/icons";

import "./Services.css";

export function Services() {
    return (
        <Row className="services">
            <div className="services__header">
                <h2>Our Main Services</h2>
            </div>
            <Row className="services__content" gutter={[20, 20]}>
                <ServiceBox
                    icon={<SearchOutlined />}
                    header="Search for property"
                    content="We have taken the time to make sure that you can find your dream home with ease."
                    link="/#"
                />
                <ServiceBox
                    icon={<HomeOutlined />}
                    header="Rent property"
                    content="We provide direct access to verified agents, so you can rent your dream home with ease."
                    link="/#"
                />
                <ServiceBox
                    icon={<AppstoreAddOutlined />}
                    header="List your property"
                    content="Make the best out of your home by listing it on our platform. We will help you find the best tenants."
                    link="/#"
                />
            </Row>
        </Row>
    )
}