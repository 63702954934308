import { StarOutlined } from "@ant-design/icons";
import "./PropertyFeaturesPage.css";

export default function PropertyFeaturesPage() {
  return (
    <>
      <div className="features__header">
        <div className="features__profile">
          <StarOutlined />
        </div>
        <div>
          <div className="text-mediumbold">Dive right inon</div>
          <div className="text-primary-grey">
            This is one of the few places in the area with a pool.
          </div>
        </div>
      </div>

      <div className="features__header">
        <div className="features__profile">
          <StarOutlined />
        </div>
        <div>
          <div className="text-mediumbold">Experienced host</div>
          <div className="text-primary-grey">
            Allison has 720 reviews for other places.
          </div>
        </div>
      </div>
    </>
  );
}
