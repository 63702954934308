import { Footer } from "antd/lib/layout/layout";

import "./AppFooter.css";

export default function AppFooter() {
  return (
    <Footer>
      {/* Add a 2023 rentfree copyright */}
      <p className="text-center">
        © 2023 Rentfree. All rights reserved.
      </p>
    </Footer>
  );
}
