import {
    CloudOutlined,
    TeamOutlined,
    ShopOutlined,
    DashboardOutlined,
    IdcardOutlined,
} from "@ant-design/icons";
import { Menu, MenuProps } from "antd";
import { Link } from "react-router-dom";
import { AppLogo } from "src/app/core/components";

export default function AdminSiderMenu({mode}: {mode: 'vertical' | 'inline'}) {
    const items: MenuProps['items'] = [
        {
            key: 'dashboard',
            icon: <DashboardOutlined />,
            label: <Link to={'/admin/dashboard'}>Dashboard</Link>
        },{
            key: 'users',
            icon: <TeamOutlined />,
            label: 'Users',
            children: [
                {
                    key: 'user/view',
                    label: <Link to={'/admin/user/view'}>View</Link>
                },
                {
                    key: 'user/create',
                    label: <Link to={'/admin/user/create'}>Create</Link>
                }
            ]
        },{
            key: 'properties',
            icon: <ShopOutlined />,
            label: 'Properties',
            children: [
                {
                    key: 'properties/view',
                    label: <Link to={'/admin/property/view'}>View</Link>
                },
                {
                    key: 'properties/create',
                    label: (
                        <Link
                            to={'/admin/property/create'}
                        >
                            <span className="label">Create</span>
                            
                        </Link>
                    )
                }
            ]
        },{
            key: 'saved-properties',
            icon: <CloudOutlined />,
            label: <Link to={'/admin/property/view'}>My Saved Properties</Link>
        },
    ];

    return (
        <>
            <AppLogo />
            <hr />
            <Menu
                theme="light"
                mode={mode}
                defaultSelectedKeys={['4']}
                items={items}
                className="admin-sidebar-menu"
            />
        </>
    )
}