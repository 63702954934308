import React from 'react'
import './App.css'
import { Provider } from 'react-redux'
import { store } from './core/store/store'
import AppRoutes from './AppRoutes'
import AdminDashboardRoutes from './features/admin/AdminDashboardRoutes'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import appTheme from './theme'

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: appTheme.colorPrimary,
                controlHeight: 42
              }
            }}
          >
            <AppRoutes />
          </ConfigProvider>
          {/* Admin */}
          <AdminDashboardRoutes />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  )
}

export default App
