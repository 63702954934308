import "./sections.css";

interface Props {
  children?: React.ReactNode;
}

export default function Sections(props: Props) {
  const { children } = props;
  return <div className="section-wrapper">{children}</div>;
}
