import * as React from "react";
import EntryWrapper from "src/app/core/components/entry-wrapper/EntryWrapper";
import SignupForm from "./components/SignupForm";
import ComfirmCode from "./components/ComfirmCode";

export default function Signup() {
  const [changeScreen, setChangeScreeen] = React.useState(false);

  const changeHandler = () => {
    setChangeScreeen((prev) => !prev);
  };

  return (
    <EntryWrapper>
      {!changeScreen ? (
        <SignupForm changeHandler={changeHandler} />
      ) : (
        <ComfirmCode />
      )}
    </EntryWrapper>
  );
}
