import { Col, Row } from 'antd';
import './HowItWorks.css';

export function HowItworks() {
    return (
        <Row>
            <div className='title-section'>
                <h2>How it works</h2>
                <p className='hero-subtitle'>
                    Lorem ipsum dolor sit amet consectetur.
                    Tristique neque a at bibendum vitae adipiscing ac maecenas.
                    Duis elit sed nunc viverra varius sed sapien tempor.
                    Commodo ipsum a nec pulvinar amet consectetur eleifend nulla a. Risus eu.
                </p>
            </div>
            <Row gutter={[20, 40]}>
                <Col sm={24} lg={12}>
                    <img
                        src="https://place-hold.it/500x500"
                        alt="How it works"
                        className='img-responsive how-it-works__image'
                    />
                </Col>
                <Col sm={24} lg={12}>
                    <div className="how-it-works__step">
                        <div className="how-it-works__step__content">
                            <h2 className="text-left">Search & Set alerts</h2>
                            <p>
                                Search with our rich filters and set alerts when you can’t find your home.
                                Rentfree will automatically alert you once we find a match.
                            </p>
                            <a className='link-button bg-primary text-white' href='/#'>Start Now</a>
                        </div>
                    </div>
                </Col>
                <Col sm={24} lg={12}>
                    <div className="how-it-works__step">
                        <div className="how-it-works__step__content">
                            <h2 className='text-left'>Find a match</h2>
                            <p>
                                Upon finding a match, book inspections and if you like what you see, proceed to show interest and verify your profile.
                            </p>
                            <a className='link-button bg-primary text-white' href='/#'>Start Now</a>
                        </div>
                    </div>
                </Col>
                <Col sm={24} lg={12}>
                    <img
                        src="/images/find-a-match.png"
                        alt="How it works"
                        className='img-responsive'
                    />
                </Col>
                <Col sm={24} lg={12}>
                    <img
                        src="https://place-hold.it/500x500"
                        alt="How it works"
                        className='img-responsive how-it-works__image'
                    />
                </Col>
                <Col sm={24} lg={12}>
                    <div className="how-it-works__step">
                        <div className="how-it-works__step__content">
                            <h2 className='text-left'>Get Verified</h2>
                            <p>
                                Verify your profile by providing necessary information that confirms you are who you say you are.
                                Your landlord is also verified.
                            </p>
                            <a className='link-button bg-primary text-white' href='/#'>Start Now</a>
                        </div>
                    </div>
                </Col>
                <Col sm={24} lg={12}>
                    <div className="how-it-works__step">
                        <div className="how-it-works__step__content">
                            <h2 className='text-left'>Move in</h2>
                            <p>
                                On request we will assist you in making moving in an easy process.
                            </p>
                            <a className='link-button bg-primary text-white' href='/#'>Start Now</a>
                        </div>
                    </div>
                </Col>
                <Col sm={24} lg={12}>
                    <img
                        src="https://place-hold.it/500x500"
                        alt="How it works"
                        className='img-responsive how-it-works__image'
                    />
                </Col>
            </Row>
        </Row>
    )
}