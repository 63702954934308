import { StarFilled } from "@ant-design/icons";
import { ReactComponent as BankIcon } from "src/app/asset/icons/bank.svg";
import "./ReviewActivities.css";
import { Button } from "antd";

export default function ReviewActivities() {
  const starArray = new Array(5).fill(0).map((_, i) => i + 1);

  return (
    <div className="review__activities_wrapper">
      <h2 className="text-primary-dark ">Review Activities</h2>
      <p>
        <span className="star">
          {starArray.map((i) => (
            <StarFilled
              key={i}
              style={{ paddingRight: "2px" }}
            />
          ))}{" "}
        </span>
        <span className="num-of-reviews">(14 Reviews)</span>
      </p>

      <div className="user__reviews">
        {starArray.map((i) => (
          <div className="user__reviews__header" key={i}>
            <div className="user__reviews__profile">
              <BankIcon width={40} />
            </div>
            <div>
              <span className="text-mediumbold">Alisson Thompson</span>
              <div>
                <span className="star">
                  {starArray.map((i) => (
                    <StarFilled
                      key={i}
                      style={{ paddingRight: "2px" }}
                    />
                  ))}{" "}
                </span>
              </div>
              <p className="text-primary-grey">
                Lorem ipsum dolor sit amet consectetur. Tristique neque a at
                bibendum vitae adipiscing ac maecenas. Duis elit sed nunc
                viverra varius sed sapien tempor. Commodo ipsum a nec pulvinar
                amet consectetur eleifend nulla a. Risus eu.
              </p>
            </div>
          </div>
        ))}
      </div>
      <Button>Show all 14 comments</Button>
    </div>
  );
}
