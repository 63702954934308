import Sider from "antd/es/layout/Sider";

import { useAppDispatch, useAppSelector } from "src/app/core/store/hooks";
import { selectSidebarCollapsed, toggleSidebar } from "../store/navigationSlice";
import AdminSiderMenu from "./AdminSiderMenu";
import { Drawer, Layout } from "antd";

export default function AdminSider() {
    const dispatch = useAppDispatch();
    const sidebarCollapsed = useAppSelector(selectSidebarCollapsed);

    return (
        <>
        <Drawer
            placement="left"
            closable={false}
            onClose={() => dispatch(toggleSidebar(true))}
            open={!sidebarCollapsed}
            key="left"
            className="drawer-sidebar"
            width={250}
        >
            <Layout
                className="layout-dashboard"
            >
                <Sider
                    width={250}
                    theme="light"
                    className="sider-primary ant-layout-sider-primary"
                    style={{ background: "transparent" }}
                >
                    <AdminSiderMenu mode="inline" />
                </Sider>
            </Layout>
        </Drawer>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onCollapse={(collapsed, type) => {
                  console.log(collapsed, type);
                }}
                trigger={null}
                width={250}
                theme="light"
                className={`sider-primary ant-layout-sider-primary active-route`}
                style={{ background: "transparent" }}
            >
                <div className="placeholder" />
                <AdminSiderMenu mode="inline" />
            </Sider>
        </>
    );
}