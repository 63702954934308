import { Menu, MenuProps } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom';

interface Action {
  onClose?: VoidFunction
}

export default function LeftMenu(props: React.ComponentProps<typeof Menu> & Action) {
  const { onClose, ...rest } = props
  const items: MenuProps['items'] = [
    {
      key: 'find-property',
      label: 'Find property',
      children: [
        { key: 'rent', label: <Link to={''}>For rent</Link> },
        { key: 'sale', label: <Link to={''}>For sale</Link> },
        { key: 'shortlet', label: <Link to={''}>Shortlet</Link> },
      ]
    },
    { key: 'find-agents', label: 'Find agents' },
    {
      key: 'about-us',
      label: <Link to={'/about-us'}>About us</Link>,
    },
    // { key: 'list', label: 'List your property', path: '/admin/property/create' },
  ];
  return (
    <Menu
      {...rest}
      items={items}
      mode="horizontal"
    />
  )
}
