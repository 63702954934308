import * as React from 'react'
import { ReactComponent as Image } from 'src/app/asset/images/image.svg'
import './EntryWrapper.css'

interface Props {
  children: React.ReactNode
}

export default function EntryWrapper(props: Props) {
  const { children } = props
  return (
    <div className='entry-wrapper'>
      <div className='first-container'>
        <Image />
        <div className='details'>
          <p className='details-text__first'>
            Easily list and rent nice apartments <br /> anywhere around you
          </p>
          <p className='details-text__second'>Sign in to explore changes we’ve made.</p>
        </div>
      </div>
      <div className='second-container'>{children}</div>
    </div>
  )
}
