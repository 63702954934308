import { PieChartOutlined, WifiOutlined } from "@ant-design/icons";
import { Button } from "antd";
import "./offers.css";

export default function Offers() {
  return (
    <>
      <h3>What this place offers</h3>
      <div className="offers__details">
        <div>
          <ul>
            <li>
              {" "}
              <WifiOutlined /> Wifi
            </li>
            <li>
              <PieChartOutlined />
              TV
            </li>
            <li>
              <PieChartOutlined />
              Hair dryer
            </li>
            <li>
              <PieChartOutlined /> Long-term stays allowed
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li>
              <PieChartOutlined />
              Pool
            </li>
            <li>
              <PieChartOutlined />
              Air Conditioning
            </li>
            <li>
              <PieChartOutlined /> Breakfast
            </li>
          </ul>
        </div>
      </div>

      <Button>Show all 14 amenities</Button>
    </>
  );
}
