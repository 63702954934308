import { EnvironmentOutlined } from '@ant-design/icons';
import './FeatureBox.css';

export interface FeatureBoxProps {
    location: string;
    duration: string;
    apartment_type: string;
    style: { backgroundImage: string, height?: number };
    className?: string;
}

export function FeatureBox(props: React.PropsWithChildren<FeatureBoxProps>) {
    return (
        <div
            className={`feature-box ${props.className}`}
            style={
                    {height: 215, ...props.style}
                }
            >
            <div className="feature-box__header">
                <span className='feature-box__pill location'>{props.location}</span>
                <span className='feature-box__pill duration'>{props.duration}</span>
                <span className='feature-box__pill apartment-type'>{props.apartment_type}</span>
            </div>
            <div className="feature-box__footer">
                <EnvironmentOutlined/>
                <div className='listing-count'>10 listings</div>
            </div>
        </div>
    )
}