import { Col, Form, Input, Select, Row, Button, Affix } from "antd";
import { ControlOutlined, SearchOutlined } from "@ant-design/icons";
import "./SearchBox.css";

export function SearchBox() {
  return (
    <Form layout="vertical" className="search-form">
      <div className="search-box">
        <div className="search-box__item">
          <Form.Item
            name={"location"}
            className="first-item"
            label={"Location"}
          >
            <Input placeholder="Where do you want to live?" />
          </Form.Item>
        </div>
        <div className="search-box__item responsive">
          <Form.Item name={"type"} label={"Property Type"}>
            <Select
              placeholder="Select type"
              options={[
                { label: "Flat", value: "flat" },
                { label: "Duplex", value: "duplex" },
              ]}
            />
          </Form.Item>
        </div>
        <div className="search-box__item responsive">
          <Form.Item name={"bed"} label={"Bed"}>
            <Select
              placeholder="Select beds"
              options={[
                { label: "One", value: 1 },
                { label: "Two", value: 2 },
              ]}
            />
          </Form.Item>
        </div>
        <div className="search-box__item responsive">
          <Form.Item name={"price"} label={"Price"}>
            <Select
              placeholder="Select range"
              options={[
                { label: "$1,000", value: 1000 },
                { label: "$1,500", value: 1500 },
              ]}
            />
          </Form.Item>
        </div>
        <div className="search-box__button">
          <Form.Item label=" ">
            <Button
              icon={<ControlOutlined />}
            >
              Filters
            </Button>
          </Form.Item>
        </div>
        <div className="search-box__button">
          <Form.Item label=" ">
            <Button
              type="primary"
              icon={<SearchOutlined />}
            >Search</Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}
