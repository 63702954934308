import { Button, Card, Col, Form, Input, Row, Select } from "antd";

export default function CreateUser() {
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    return (
        <Row>
            <Col lg={{span: 12, offset: 6}} sm={{span: 24}}>
                <Card
                    title="Create User"
                >
                    <Form
                        {...layout}
                    >
                        <Form.Item label="First Name">
                            <Input type="text" placeholder="Enter first name" />
                        </Form.Item>
                        <Form.Item label="Last Name">
                            <Input type="text" placeholder="Enter last name" />
                        </Form.Item>
                        <Form.Item label="Email">
                            <Input type="email" placeholder="Enter email" />
                        </Form.Item>
                        <Form.Item label="Phone Number">
                            <Input type="text" placeholder="Enter phone number" />
                        </Form.Item>
                        <Form.Item label="User Type">
                            <Select
                                placeholder='Select user type'
                                options={[
                                    { label: "Admin", value: "admin" },
                                    { label: "Agent", value: "agent" },
                                    { label: "User", value: "user" },
                                ]}
                            />
                        </Form.Item>
                        <Row style={{ justifyContent: 'flex-end' }}>
                            <Button
                                className="btn btn-primary"
                                type="primary"
                                htmlType="submit"
                            >
                                Create User
                            </Button>
                        </Row>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
}