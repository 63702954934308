import { Button, Input } from "antd";
import "./BookNow.css";

export default function BookNow() {
  return (
    <div className="book-now__Container">
      <div className="book-now__wrapper">
        <h3>₦ 300,000/year</h3>
        <p>
          Free cancellation before 22 Jun. Cancel before check-in on 23 Jun for
          a partial refund.
        </p>
        <Input placeholder="Send a message" className="input" />
        <Button type="primary" className="button" block>
          Book Now
        </Button>
      </div>
    </div>
  );
}
