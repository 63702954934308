import { ReactComponent as Like } from 'src/app/asset/icons/like.svg'
import { ReactComponent as Share } from 'src/app/asset/icons/share.svg'
import { ReactComponent as Eye } from 'src/app/asset/icons/eye.svg'
import { ReactComponent as Bedroom } from 'src/app/asset/icons/bedroom.svg'
import { ReactComponent as Location } from 'src/app/asset/icons/Location.svg'
import { ReactComponent as Bathroom } from 'src/app/asset/icons/bathroom.svg'
import './PropertyCard.css'

interface PropertyCardProps extends React.BaseHTMLAttributes<HTMLDivElement | HTMLAnchorElement> {
  title: string
  img: string
  price: string
  location: string
  description: string
  url?: string
}

export default function PropertyCard(props: PropertyCardProps) {
  const { title, img, price, location, description, url } = props

  return (
    <a className='ApartmentCardContainer' href={url}>
      <div className='cardimg'>
        <img src={img} alt='apartment' />
      </div>
      <div className='carddetails'>
        <p className='time text-small text-subdued'>10 min ago</p>
        <p className='text-medium text-semibold'>{title}</p>
        <p className='text-blue text-semibold text-blue' style={{ marginTop: '9px' }}>
          {price}
        </p>
        <div className='address'>
          <Location className='icon' />
          <p className='text-subdued text-small text-semibold'>{location}</p>
        </div>
        <p className='text-subdued text-small' style={{ marginTop: '9px' }}>
          {description}
          <a href={url} className='readmore'>
            {' '}
            Read more...{' '}
          </a>
        </p>
        <div className='address'>
          <p className='text-smallest text-subdued'>
            Current pools (<span className='readmore'>20</span>)
          </p>
          <Eye className='icon ' />
        </div>

        <div className='cardfooter'>
          <div className='properties'>
            <div className='first'>
              <Bedroom />
              <p className='text-small text-subdued'>2 bedroom</p>
            </div>
            <div className='first'>
              <Bathroom />
              <p className='text-small text-subdued'>2 bathroom</p>
            </div>
          </div>
          <div className='cardaction'>
            <div className='first'>
              <Share />
              <p className='text-small text-subdued'>Share</p>
            </div>
            <div className='first'>
              <Like />
              <p className='text-small text-subdued'>Like</p>
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}
