import { Col, Input, Form } from "antd";

export default function LocationDetails() {
    return (
        <>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item label="Address">
                    <Input
                        type="text"
                        placeholder="Enter address"
                    />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Form.Item label="City">
                    <Input
                        type="text"
                        placeholder="Enter city"
                    />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Form.Item label="State">
                    <Input
                        type="text"
                        placeholder="Enter state"
                    />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Form.Item label="Country">
                    <Input
                        type="text"
                        placeholder="Enter country"
                    />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item label="Latitude">
                    <Input
                        type="text"
                        placeholder="Enter latitude"
                    />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item label="Longitude">
                    <Input
                        type="text"
                        placeholder="Enter longitude"
                    />
                </Form.Item>
            </Col>
        </>
    )
}