import { ReactComponent as Logo } from "src/app/asset/images/rentfree-logo.svg";
import { ReactComponent as Info } from "src/app/asset/icons/info.svg";
import Message from "src/app/asset/images/message.png";
import PinInput from "react-pin-input";
import "./ComfirmCode.css";

export default function ComfirmCode() {
  return (
    <div className="comfirmCodeContainer">
      <Logo />
      <br />
      <img src={Message} alt="message icon" />
      <div className="signup">
        <p>Check your mail</p>
        <p>
          Please enter the 6 digit code sent to{" "}
          <span>Draxler04123@gmail.com</span>
        </p>
        <PinInput
          length={6}
          initialValue=""
          onChange={(value, index) => {}}
          type="numeric"
          inputMode="number"
          inputStyle={{ marginRight: "8px" }}
          onComplete={(value, index) => {}}
          autoSelect={true}
        />
        <button
          className="entry-button"
          type="button"
          //   disabled={buttonDisable}
          onClick={() => console.log("hay")}
        >
          Confirm code
        </button>
        <p>Didn’t get the code?</p>
        <div className="resend">
          <Info />
          <p>Resend code in 02:34</p>
        </div>
      </div>
    </div>
  );
}
