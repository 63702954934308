import * as React from 'react'
import { ReactComponent as Logo } from 'src/app/asset/images/rentfree-logo.svg'
import { Button, Form, Input } from 'antd'
import ThirdParties from 'src/app/core/components/entry-wrapper/ThirdParties'
import './SignupForm.css'

interface Props {
  changeHandler: () => void
}

export default function SignupForm(props: Props) {
  const { changeHandler } = props

  const onFinish = (values: any) => {
    changeHandler()
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div className='signin-container'>
      <a href='/'>
        <Logo />
      </a>
      <div className='signup'>
        <p className='signup-text'>Sign up</p>
        <p className='signup-details'>To sign up, fill in your personal details below</p>

        {/* Signup Form */}

        <div className='signupform'>
          <Form name='basic' layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete='off'>
            <Form.Item label='Name' name='name' rules={[{ required: true, message: 'Please input your mane!' }]}>
              <Input placeholder='Enter your name' className='input' />
            </Form.Item>

            <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Please input your email!' }]}>
              <Input placeholder='Enter your email' className='input' />
            </Form.Item>

            <Form.Item
              label='Password'
              name='password'
              rules={[{ required: true, message: 'Please input your password!' }]}
              extra=' Must be at least 8 characters.'
            >
              <Input.Password placeholder='Enter your password' className='input' />
            </Form.Item>

            <Form.Item>
              <Button type='primary' htmlType='submit' className='entry-button'>
                Get Started
              </Button>
            </Form.Item>
          </Form>
        </div>

        <ThirdParties page='Sign up' />

        <div className='login'>
          Already have an account? <a href='/login'>Login</a>
        </div>
      </div>
    </div>
  )
}
