import { Button, Card, Col, Form, Row, Steps, message } from "antd";
import { useState } from "react";
import BasicDetails from "./components/BasicDetails";
import LocationDetails from "./components/LocationDetails";
import Media from "./components/Media";
import FinancialDetails from "./components/FinancialDetails";

export default function ListProperty() {
    const [current, setCurrent] = useState(0);

    const steps = [
        {
            title: 'Basic Information',
            content: <BasicDetails />,
        },{
            title: 'Financial Details',
            content: <FinancialDetails />,
        },{
            title: 'Location',
            content: <LocationDetails />,
        },{
            title: 'Media',
            content: <Media />,
        },{
            title: 'Publish',
            content: 'Last-content',
        },
    ];

    const prev = () => {
        setCurrent(current - 1);
    };

    const next = () => {
        setCurrent(current + 1);
    };


    return (
        <Row>
            <Col offset={3} span={18}>
                <Steps
                    current={current}
                    items={steps}
                    style={{ marginBottom: 40 }}
                    progressDot
                    responsive
                />
                <Card>
                    <Form
                        name="property"
                        layout="vertical"
                    >
                        <Row gutter={[16, 16]}>{steps[current].content}</Row>
                        <Row style={{ marginTop: 24, justifyContent: 'flex-start', columnGap: 10 }}>
                            {current > 0 && (
                                <Button onClick={() => prev()}>
                                    Previous
                                </Button>
                            )}
                            {current < steps.length - 1 && (
                                <Button 
                                    type="primary" onClick={() => next()}
                                >
                                    Next
                                </Button>
                            )}
                            {current === steps.length - 1 && (
                                <Button
                                    type="primary"
                                    onClick={() => message.success('Processing complete!')}
                                >
                                    Done
                                </Button>
                            )}
                        </Row>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
}