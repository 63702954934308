import { ApartmentOutlined, HomeOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Row, Card, Col, Typography } from "antd";

export default function Dashboard() {
    const { Title } = Typography;
    const count = [
        {
          today: "Total Users",
          title: "1,000",
          persent: "",
          icon: <TeamOutlined />,
          bnb: "bnb2",
        },
        {
          today: "Total Agents",
          title: "200",
          persent: "",
          icon: <UserOutlined />,
          bnb: "bnb2",
        },
        {
          today: "Total Clients",
          title: "3,200+",
          persent: "",
          icon: <UserOutlined />,
          bnb: "redtext",
        },
        {
          today: "Total Properties",
          title: "10,200",
          persent: "",
          icon: <HomeOutlined />,
          bnb: "bnb2",
        },
      ];
    return (
        <>
            <Row className="rowgap-vbox" gutter={[24, 0]}>
                {count.map((c, index) => (
                    <Col
                    key={index}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={6}
                    xl={6}
                    className="mb-24"
                    >
                    <Card bordered={false} className="criclebox ">
                        <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                            <Col xs={18}>
                            <span>{c.today}</span>
                            <Title level={3}>
                                {c.title} <small className={c.bnb}>{c.persent}</small>
                            </Title>
                            </Col>
                            <Col xs={6}>
                            <div className="icon-box">{c.icon}</div>
                            </Col>
                        </Row>
                        </div>
                    </Card>
                    </Col>
                ))}
            </Row>
        </>
    );
}