import { Route, Routes } from "react-router-dom";
import AdminDashboard from "./AdminDashboard";
import ListProperty from "./property/ListProperty";
import ViewProperty from "./property/Property";
import ViewProperties from "./property/ViewProperties";
import Dashboard from "./dashboard/Dashboard";
import ViewUsers from "./user/ViewUsers";
import ViewUser from "./user/ViewUser";
import CreateUser from "./user/CreateUser";

export default function AdminDashboardRoutes() {
    return (
        <Routes>
            <Route path="/admin" element={<AdminDashboard />}>
                <Route path="property">
                    <Route path="create" element={<ListProperty />}></Route>
                    <Route path="view" element={<ViewProperties />}></Route>
                    <Route path="view/:propertyId" element={<ViewProperty />}></Route>
                </Route>
                <Route path="user">
                    <Route path="view" element={<ViewUsers />} />
                    <Route path="view/:userId" element={<ViewUser />} />
                    <Route path="create" element={<CreateUser />} />
                    <Route path="update/:userId" element={<ViewUser />} />
                </Route>
                <Route path="dashboard" element={<Dashboard />} />
            </Route>
        </Routes>
    );
}