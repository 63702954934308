import { Link } from "react-router-dom";

export default function AppLogo() {
    return (
        <Link to="/">
            <div className="brand">
                <img
                    src="/rentfree-logo.svg"
                    alt="Rentfree logo"
                />
            </div>
        </Link>
    )
}