import { Col, Row } from 'antd'
import { ReactComponent as Like } from 'src/app/asset/icons/like.svg'
import { ReactComponent as Share } from 'src/app/asset/icons/share.svg'
import { ReactComponent as Logo } from 'src/app/asset/images/rentfree-logo.svg'
import { CheckOutlined } from '@ant-design/icons'
import BookNow from './components/book-now/BookNow'
import ReviewActivities from './components/review-activities/ReviewActivities'
import Sections from './components/sections/sections'
import PropertyFeaturesPage from './components/features/PropertyFeaturesPage'
import Offers from './components/offers/offers'
import { Breadcrumb } from 'antd'
import './propertyDetails.css'

export default function PropertyDetails() {
  return (
    <div className='property-container page-content'>
      <Breadcrumb
        items={[
          {
            title: <a href='/'>Home</a>
          },
          {
            title: <a href='/property'>Property for rent</a>
          },
          {
            title: '2 bedroom flat at ikeja'
          }
        ]}
        style={{ marginTop: '44px' }}
      />
      <div className='header-text'>
        <p>2 bedroom apartment off adeniyi jones</p>
        <div className='action'>
          <div className='first'>
            <Share />
            <p className='text-small text-subdued'>Share</p>
          </div>
          <div className='first'>
            <Like />
            <p className='text-small text-subdued'>Like</p>
          </div>
        </div>
      </div>

      <Row gutter={[16, 32]}>
        <Col xs={{ span: 24 }} lg={{ span: 14 }}>
          <img src='https://place-hold.it/500x500' alt='property details img' className='img-responsive property__image' />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Row gutter={[0, 16]}>
            <img src='https://place-hold.it/500x500' alt='property details img' className='img-responsive property__image2' />

            <img src='https://place-hold.it/500x500' alt='property details img' className='img-responsive property__image2' />
          </Row>
        </Col>
      </Row>
      <Row className='details__page'>
        <Col xs={{ span: 24 }} lg={{ span: 14 }}>
          <div className='property__serviced'>
            <div>
              <h4 className='text-primary-grey'>Serviced</h4>
              <span>1 bedroom . 1 bathroom</span>
            </div>
            <div className='badge__wrapper'>
              <span className='badge'>
                Verified <CheckOutlined />
              </span>
            </div>
          </div>

          <Sections>
            <PropertyFeaturesPage />
          </Sections>

          <Sections>
            <Logo />
            <p style={{ marginTop: '12px' }}>
              Every booking includes free protection from Host cancellations, listing inaccuracies, and other issues like trouble checking
              in.
            </p>
            {/* <a>Learn more</a> */}
          </Sections>
          <Sections>
            <p>
              Adaaran Club Rannalhi is featured among the best hotels in Maldives and sits exclusively at the tip of the South Male atoll
              within the exotic collection of islands known as the Maldives. Its unique location offers access to pristine beaches,
              excellent scuba diving opportunities and a relaxed environment with easy access to the capital city of Male.
            </p>
          </Sections>
          <Sections>
            <Offers />
          </Sections>
          <Sections>
            <ReviewActivities />
          </Sections>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <BookNow />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className='property-search__location'>
            <h3>Search location</h3>
            <div>
              <img src='https://place-hold.it/500x500' alt='property details img' className='img-responsive property__image' />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
