import { ReactComponent as Facebook } from "src/app/asset/icons/facebook.svg";
import { ReactComponent as Google } from "src/app/asset/icons/google.svg";
import { ReactComponent as Twitter } from "src/app/asset/icons/twitter.svg";
import "./ThirdParties.css";

interface Props {
  page: string;
}

export default function ThirdParties({ page }: Props) {
  return (
    <div>
      <div className="or">
        <span>Or</span>
      </div>
      <div>
        <div className="accounts">
          <Facebook />
          <p>{page} with Facebook</p>
        </div>
        <div className="accounts">
          <Twitter />
          <p>{page} with Twitter</p>
        </div>
        <div className="accounts">
          <Google />
          <p>{page} with Google</p>
        </div>
      </div>
    </div>
  );
}
