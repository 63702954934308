import { Button, Checkbox, Form, Input } from 'antd'
import './LoginForm.css'

export default function LoginForm() {
  const onFinish = (values: any) => {
    console.log('Success:', values)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div className='login-wrapper'>
      <Form name='basic' layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete='off'>
        <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Please input your email!' }]}>
          <Input placeholder='Enter your email' className='input' />
        </Form.Item>

        <Form.Item label='Password' name='password' rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password placeholder='Enter your password' className='input' />
        </Form.Item>

        <div className='check'>
          <Form.Item name='remember' valuePropName='checked'>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <div>
            <p>Forgot password</p>
          </div>
        </div>

        <Form.Item>
          <Button type='primary' htmlType='submit' className='entry-button'>
            Sign in
          </Button>
        </Form.Item>
      </Form>
      {/* <div className="login-input__wrapper">
        <label>Email</label>
        <Input
          placeholder="Enter your email"
          value={email}
          onChange={(t) => setEmail(t.target.value)}
        />
      </div>
      <div className="login-input__wrapper">
        <label>Password</label>
        <Input
          placeholder="Enter your password"
          type="password"
          value={password}
          onChange={(t) => setPassword(t.target.value)}
        />
      </div>
      <div className="check">
        <div>
          <input type="checkbox" id="Remember" name="Remember" />
          <label htmlFor="Remember">Remember me</label>
        </div>
        <div>
          <p>Forgot password</p>
        </div>
      </div>
      <button
        className="entry-button"
        type="button"
        disabled={buttonDisable}
        onClick={() => console.log("hay")}
      >
        Sign in
      </button> */}
    </div>
  )
}
