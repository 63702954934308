import { Col, Row } from "antd";
import { HowItworks } from "../home/components/how-it-works/HowItWorks";
import { Services } from "../home/components/services/Services";

import "./AboutUs.module.css";

export default function AboutUs() {
    return (
        <div className='about-us site-layout'>
            <Row justify='center' className='home' gutter={[0, 40]}>
                <Col span={24}>
                    <h1 className='hero-header'>Find your home without breaking a sweat</h1>
                    <p className='hero-subtitle'>
                        Lorem ipsum dolor sit amet consectetur. Tristique neque a at bibendum vitae adipiscing ac maecenas. Duis elit sed nunc viverra
                        varius sed sapien tempor. Commodo ipsum a nec pulvinar amet consectetur eleifend nulla a. Risus eu.
                    </p>
                </Col>
                <Col span={24}>
                    <Services />
                </Col>
                <Col span={24}>
                    <HowItworks />
                </Col>
            </Row>
        </div>
    )
}