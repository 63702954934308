import React from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import { Home } from './features/home'
import { Main } from './core/components'
import Signup from './features/signup/Signup'
import Login from './features/login/Login'
import { PropertyDetails } from './features/property-details'
import Property from './features/property/Property'
import AboutUs from './features/about-us/AboutUs'

export default function AppRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Main />}>
        <Route index element={<Home />} />
        <Route path='/property' element={<Property />} />
        <Route path='/property/property-details/:id' element={<PropertyDetails />} />
        <Route path='/about-us' element={<AboutUs />} />
      </Route>
      <Route path='/register' element={<Signup />} />
      <Route path='/login' element={<Login />} />
    </Routes>
  )
}
