import { createSlice } from "@reduxjs/toolkit";

interface NavigationState {
    sidebarCollapsed: boolean;
}

const initialState: NavigationState = {
    sidebarCollapsed: true
};

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        toggleSidebar: (state, action) => {
            state.sidebarCollapsed = action.payload;
        }
    }
});

export const { toggleSidebar } = navigationSlice.actions;
export const selectSidebarCollapsed = (state: any) => state.navigation.sidebarCollapsed;

export default navigationSlice.reducer;