import { Input, Select, Form, Col, InputNumber } from "antd";

export default function FinancialDetails() {
    const suffixSelector = (
        <Form.Item name="currency" label="" noStyle>
            <Select
                style={{
                    width: 80,
                }}
                defaultValue={'NGN'}
            >
                <Select.Option value="NGN">Naira</Select.Option>
                <Select.Option value="USD">Dollar</Select.Option>
            </Select>
        </Form.Item>
    );
    return (
        <>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
                name="price"
                label="Price"
                rules={[{ required: true, message: 'Please input donation amount!' }]}
            >
                <InputNumber
                    addonAfter={suffixSelector}
                    style={{ width: '100%' }}
                    placeholder="Enter price"
                />
            </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item label="Service Charge">
                    <Input
                        type="number"
                        placeholder="Enter service charge"
                    />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Form.Item label="Agency Fee">
                    <Input
                        type="number"
                        placeholder="Enter agency fee"
                    />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Form.Item label="Legal Fee">
                    <Input
                        type="number"
                        placeholder="Enter legal fee"
                    />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Form.Item label="Caution Fee">
                    <Input
                        type="number"
                        placeholder="Enter caution fee"
                    />
                </Form.Item>
            </Col>
        </>
    );
}