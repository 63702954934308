import PropertyCard from './components/PropertyCard'
import NewsLetterForm from './components/NewsLetterForm'
import { Col, Pagination, Row } from 'antd'
import Apartment from 'src/app/asset/images/pic.png'
import { Breadcrumb } from 'antd'
import './property.css'
import { FeatureBox, FeatureBoxProps } from 'src/app/core/components'

export default function Property() {
  const list = new Array(5).fill(0).map((_, i) => i + 1)
  const featured: FeatureBoxProps[] = [
    {
      location: 'Lagos',
      duration: '12 months',
      apartment_type: 'Flat',
      style: { backgroundImage: 'url(https://place-hold.it/300x500)' },
    },
    {
      location: 'Abuja',
      duration: '12 months',
      apartment_type: 'Flat',
      style: { backgroundImage: 'url(https://place-hold.it/300x500)' }
    },
    {
      location: 'Port Harcourt',
      duration: '12 months',
      apartment_type: 'Flat',
      style: { backgroundImage: 'url(https://place-hold.it/300x500)' }
    }
  ]


  return (
    <div className='apartment-wrapper page-content'>
      <Breadcrumb
        items={[
          {
            title: <a href='/'>Home</a>
          },
          {
            title: 'Property for rent'
          }
        ]}
      />
      {/* <p className='location'> Properties for rent (Ikeja, Lagos) </p> */}

      <Row className='main' gutter={[30, 30]}>
        <Col xl={16} sm={24}>
          <Row gutter={[10, 10]} className='featured-boxes'>
            {featured.map((feature, index) => (
              <Col sm={24} xs={24} lg={8} key={index}>
                <FeatureBox {...feature} />
              </Col>
            ))}
          </Row>

          <div className='cards'>
            {list.map((_, i) => (
              <PropertyCard
                key={i}
                title='2 bedroom flat / Apartment for rent'
                img={Apartment}
                price='₦ 300,000'
                location='Adeniyi jones, ikeja, Lagos'
                description='Lorem ipsum dolor sit amet consectetur. Tristique neque a at bibendum
              vitae adipiscing ac maecenas.'
                url={`/property/property-details/${i}`}
              />
            ))}
            <div className='pagination'>
              <Pagination defaultCurrent={1} total={50} />
            </div>
          </div>
        </Col>
        <Col xl={8} sm={24} className='news-letter'>
          <NewsLetterForm />
        </Col>
      </Row>
    </div>
  )
}
